.App {
  text-align: center;
}

.App-logo {
  height: 16px;
  pointer-events: none;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: -2px;
}

.Firebase-logo { 
  height: 16px;
  margin-bottom: -2px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
  margin-top: 5vh;
  margin-left: 5vw;
}

.secondary-container {
  margin-top: 3vh;
  margin-left: 7vw;
  text-align: left;
}

.typing-container {
  width: 60vw;
  height: 15vh;
  overflow: wrap;
  top: 40px;
  text-align: left;
  font-size: calc(12px + 2vmin);
  line-height: 1.7; 
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.typing-text {
  display: inline; /* Keeps text inline */
  animation: typing 8s steps(30, end), blink-caret 0.75s step-end infinite; /* Typing effect and caret blink */
  color: whitesmoke;
  text-decoration: none; 
}

.secondary-typing-text { 
  font-size: calc(8px + 2vmin);
  color: whitesmoke;
  text-decoration: none; 
}

.hover-text:hover {
  cursor: pointer;
}

.bottom-margin {
  margin-bottom: 10px;
}

a:hover {
  color: steelblue;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.footer {
  position: fixed;
  left: 5%;
  bottom: 0;
  width: 90%;
  background-color: #282c34;
  color: white;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}

.small-text {
  font-size: calc(1px + 2vmin);
  width: 40%;
  overflow: wrap;
}

.hover-box {
  transition: box-shadow 0.3s ease;
}

.hover-box:hover {
  box-shadow: 5px 5px 10px rgba(220, 220, 220, 0.5);
  cursor: pointer;
}

.startup-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60vw;
  flex-wrap: wrap;
  /* border: green;
  border-width: 2px;
  border-style: solid; */
}

/* For screens smaller than 750px (e.g., tablets and mobile devices) */
@media (max-width: 750px) {
  .typing-container {
    width: 95%; /* Full width for smaller screens */
  }

  .small-text {
    font-size: calc(6px + 2vmin);
  }

  .startup-container { 
    width: 90%;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  animation-delay: 3s
}

.fade-in:nth-child(1) { animation-delay: 3.5s; }  /* First div fades in after 2s */
.fade-in:nth-child(2) { animation-delay: 4s; } /* Second div after 2.5s */
.fade-in:nth-child(3) { animation-delay: 4.5s; }  /* Third div after 3s */
.fade-in:nth-child(4) { animation-delay: 5s; } /* Fourth div after 3.5s */
.fade-in:nth-child(5) { animation-delay: 5.5s; } /* Fourth div after 3.5s */

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
